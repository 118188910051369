/* eslint-disable */

import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import CustomInput from '../../../components/Elements/TextInput/CustomInput';
import TimeInput from '../../../components/Elements/TextInput/TimeInput';
import Close from '../../../components/SvgIcons/Close';
import { addMinutes, combineBookingsByAddress, secondsToMinutes, setDBTime } from '../../../lib/common';
import displayNotification from '../../../utils/notification';
import styles from './styles.module.scss';
import { updateSharedTrips } from '../../../services/dispatch';
import CancelUpdatePickupTime from './cancelUpdatePTModal';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);

const { host, protocol } = window.location;
const dropOffUrl = `${protocol}//${host}/resources/images/dropoff_pin_same-size.png`;
const pickupOffUrl = `${protocol}//${host}/resources/images/pickup_pin_same-size.png`;
const SameAddress = 'Same address as above';

const UpdatePickupTime = ({
  close: backToDispatchBoard,
  programId,
  trips: tripsProps,
  dispatchDate,
  unsetSpinner,
  setSpinner,
  shuttleAnchorLocation,
  tripId,
  updateTimeAfterShareTripsUpdate,
}) => {
  const [trips, setTrips] = useState([]);
  const [startTime, setStartTime] = useState();
  const [loadTime, setLoadTime] = useState(0);
  const [computedTime, setComputedTime] = useState();
  const [updatedPickupTime, setUpdatedPickupTime] = useState();
  const [showCancelModal, setShowcancelModal] = useState(false);

  const handleRecomputeTime = () => {
    if (!startTime) {
      return displayNotification('Please set up the first pickup time.', 'Error', 'error');
    }

    if (!startTime || !loadTime || loadTime < !1) {
      return displayNotification('Please set up the load/unload time.', 'Error', 'error');
    }

    setComputedTime(computeTimeDifferences(trips));
  };

  const close = () => {
    setShowcancelModal(true);
  };

  const handleLockThisRoute = async () => {
    if (!updatedPickupTime) {
      return displayNotification('Please make sure to update time by clicking update time button');
    }
    setSpinner();
    // recompute to make sure that changes applied are correct.
    const computedTime = computeAllPickupTime(false);
    try {
      // get all booking ids
      // const bookingIds = trips.map((trip) => {
      //   return trip.bookings[0].id;
      // });
      // convert time to db Type and to db format
      trips.map((trip, index) => {
        if (trip.bookings[0].stop_type === 'pickup') {
          trip.bookings[0].scheduled_pickup_time = dayjs(
            setDBTime(computedTime[index], new Date(trip.bookings[0].scheduled_pickup_time))
          ).format('YYYY-MM-DDTHH:mm:ss');
        } else {
          trip.bookings[0].scheduled_dropoff_time = dayjs(
            setDBTime(computedTime[index], new Date(trip.bookings[0].scheduled_dropoff_time))
          ).format('YYYY-MM-DDTHH:mm:ss');
        }
        return { ...trip, bookings: [...trip.bookings] };
      });
      const params = {
        trip_id: tripId,
        stop_order: combineBookingsByAddress(trips),
        travel_distance: tripsProps?.travel_distance,
        travel_time: tripsProps?.travel_time,
        load_unload_time: parseInt(loadTime) * 60,
        shuttle_anchor_location: shuttleAnchorLocation,
      };
      const resp = await updateSharedTrips(params);
      if (resp.status === 'success') {
        updateTimeAfterShareTripsUpdate(resp.data);
        displayNotification('Successfully updated shared trips', 'Success', 'success');
        backToDispatchBoard();
      } else {
        if (res.message.error_code === 'MANUALDISPATCHPROGRAMERROR') {
          displayNotification(
            'The booking can’t be dispatched because the vehicle doesn’t belong to this program anymore.',
            'Error',
            'error'
          );
        } else displayNotification('Failed to update shared trip', 'Error', 'error');
      }
    } catch (e) {
      console.error(e);
    }
    unsetSpinner();
  };

  const computeTimeDifferences = (trips) => {
    let results = [];
    for (let i = 0; i < trips.length - 1; i++) {
      const currentStop = trips[i];
      const nextStop = trips[i + 1];
      // Extract the single booking from each stop
      const currentBooking = currentStop.bookings[0];
      const nextBooking = nextStop.bookings[0];
      // Extract all possible times and find the first valid time
      const currentTime = currentBooking.scheduled_pickup_time || currentBooking.scheduled_dropoff_time;
      const nextTime = nextBooking.scheduled_pickup_time || nextBooking.scheduled_dropoff_time;
      if (currentTime && nextTime) {
        // Calculate the time difference in seconds
        const timeDifference = (new Date(nextTime) - new Date(currentTime)) / 1000;
        const bookingId = nextStop.bookings[0].id;
        results.push({
          bookingId,
          passenger: `${currentBooking.passenger_firstname} ${currentBooking.passenger_lastname}`,
          from: currentStop.address,
          to: nextStop.address,
          timeDifferenceSeconds: timeDifference,
        });
      }
    }
    return results;
  };
  // this code needs to be refactored, its being used in multiple area
  const checkIfSameAddress = (address, index) => {
    // Check if the current address is the first occurrence in the array
    if (index > 0 && trips[index].address === trips[index - 1].address) {
      return SameAddress;
    }
    return address;
  };

  const computeAllPickupTime = (showMessage = true) => {
    const adjustedTime = [startTime];
    computedTime.forEach((time, index) => {
      adjustedTime.push(
        addMinutes(adjustedTime[index], secondsToMinutes(time.timeDifferenceSeconds) + parseInt(loadTime))
      );
    });
    setUpdatedPickupTime(adjustedTime);
    if (showMessage) displayNotification('Successfully updated all pickup time', 'Success', 'success');
    return adjustedTime;
  };

  useEffect(() => {
    if (computedTime) {
      computeAllPickupTime(updatedPickupTime?.length > 0);
    }
  }, [computedTime]);

  useEffect(() => {
    if (tripsProps && tripsProps.stop_order) {
      setTrips(tripsProps.stop_order || []);
      setLoadTime(tripsProps.load_unload_time / 60);
      if (tripsProps.stop_order[0].bookings[0] && tripsProps.stop_order[0].bookings[0].scheduled_pickup_time) {
        const formatedTime = dayjs(tripsProps.stop_order[0].bookings[0].scheduled_pickup_time).format('hh:mm A');
        setStartTime(formatedTime);
      }
    }
  }, [tripsProps]);

  useEffect(() => {
    // pre load updated PU on first load
    if (startTime && !updatedPickupTime) {
      handleRecomputeTime();
    }
  }, [startTime]);

  return (
    <>
      <CancelUpdatePickupTime show={showCancelModal} yes={backToDispatchBoard} no={() => setShowcancelModal(false)} />
      <div className={styles.modalContainer}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <div className={styles.tripReorderContainer}>
              <div className={styles.mapContainer}>
                <div className={styles.header}>Edit Shared Trip</div>
                <div className={`mt-4 ${styles.subHeader}`}>Current route</div>
                <div className={`pl-4 mt-4 pb-2 ${styles.paragraph} ${styles.mobileViewHeader}`}>
                  <span className="mr-4">Updated PU</span>
                  <span className="ml-4 pl-3">Current PU without (un)loading time</span>
                </div>
                <ul className={`${styles.tripList}`}>
                  {trips.map((trip, index) => {
                    const booking = trip.bookings[0];
                    const isPickup = booking.stop_type === 'pickup';
                    let time = isPickup ? booking.scheduled_pickup_time : booking.scheduled_dropoff_time;
                    const tripTime = dayjs(time.replace(/([0-9]{1,2})([APM]{2})/, '$1 $2')).format('hh:mm A');

                    let adjustedPT = tripTime;

                    if (updatedPickupTime?.[index]) {
                      adjustedPT = updatedPickupTime[index];
                    }
                    const address = checkIfSameAddress(trip.address, index);

                    return (
                      <li key={trip.id} className={styles.optimizeTime}>
                        <div className={styles.pickupTime}>{adjustedPT}</div>
                        <div className={styles.tripItem}>
                          <div className={styles.tripIcon}>
                            <img src={isPickup ? pickupOffUrl : dropOffUrl} alt="tripIcon" />
                          </div>
                          <div className={`${styles.tripName} ${styles.time} mr-3`}>{tripTime}</div>
                          <div className={styles.tripDetails}>
                            <div className={`${styles.tripName} ${address === SameAddress && styles.sameAddress}`}>
                              {address}
                            </div>
                            <div className={styles.tripAddress}>
                              {booking.passenger_firstname} {booking.passenger_lastname}{' '}
                              {booking.mobility_assistance.length
                                ? booking.mobility_assistance.map((mob) => `, ${mob}`)
                                : ''}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className={styles.tripsContainer}>
                <div className={styles.header}>
                  <div>&nbsp;</div>
                  <div className={styles.closeIcon}>
                    <IconButton onClick={close}>
                      <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      </svg>
                    </IconButton>
                  </div>
                </div>

                <div className={`mt-3 ${styles.tripReorder} `}>
                  <div className={styles.subHeader}>Step 2: Edit pickup time</div>
                  <p className={`mt-1 ${styles.paragraph2}`}>
                    Based on the estimate travel time, adjust the loading and unloading time, and the first pickup time
                    to ensure the on-time performance before creating the shared ride.
                  </p>

                  <div className={`mt-4 ${styles.tripList}`}>
                    <div className={styles.inputsContainer}>
                      <CustomInput
                        label="Estimated total travel time"
                        value={`${secondsToMinutes(tripsProps?.travel_time)} minutes`}
                      />
                      <CustomInput
                        inputCustomClass={styles.inputColor}
                        inputType="number"
                        readOnly={false}
                        isEdit
                        label="Load or unload time, minutes"
                        value={loadTime}
                        onChangeEvent={(e) => setLoadTime(e.target.value)}
                        icon={<span style={{ background: '#fff', padding: '1px' }}>minutes</span>}
                        iconType="component"
                      />
                    </div>
                    <div className={styles.inputsContainer}>
                      <TimeInput
                        inputCustomClass={styles.inputColor}
                        label="Set the first pickup time"
                        subLabel=""
                        value={startTime}
                        icon="/resources/images/Time_Square.png"
                        isEdit
                        placeHolder=""
                        readOnly={false}
                        onChangeEvent={(value) => setStartTime(value)}
                        programId={programId}
                        useAPITime
                        selectedDate={new Date(dispatchDate)}
                        showHiddenDiv={false}
                      />

                      <button className="btn btn-outline-primary" onClick={handleRecomputeTime}>
                        Update all pickup time
                      </button>
                    </div>
                  </div>
                </div>
                <div className={`mt-4 ${styles.optimizeButtons}`}>
                  <button className="btn btn-secondary" onClick={close}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" style={{ float: 'right' }} onClick={handleLockThisRoute}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdatePickupTime;
