import React from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import useWindowSize from '../../hooks/useWindowSize';
import CircleClose from '../SvgIcons/CircleClose';
import styles from './modal2.module.scss';
import Question from '../SvgIcons/Question';
import Info from '../SvgIcons/Info';
import Checkmark from '../SvgIcons/Checkmark';
import Mail from '../SvgIcons/Mail';

export default function DuetModal2({
  children,
  show,
  hideOnClickOutside = false,
  onClickClose,
  onHide,
  showCircleClose = true,
  classes = {},
  styles: stylesProp = {},
}) {
  if (!show) {
    return null;
  }

  const getModalHideOnClickProps = () => {
    if (hideOnClickOutside) {
      return {
        onClick: onHide,
        role: 'button',
        tabIndex: 0,
        onKeyDown: () => {},
      };
    }

    return {};
  };

  const getModalContentHideOnClickProps = () => {
    if (hideOnClickOutside) {
      return {
        onClick: (e) => e.stopPropagation(),
        role: 'button',
        tabIndex: 0,
        onKeyDown: () => {},
      };
    }

    return {};
  };

  const handleClickCircleClose = (e) => {
    if (hideOnClickOutside) {
      e.stopPropagation();
    }

    if (onClickClose) {
      onClickClose(e);
    }
  };

  return createPortal(
    <>
      <div className={classNames(styles['modal-backdrop'], classes.modalBackdrop)} style={stylesProp.modalBackdrop} />
      <div className={classNames(styles.modal, classes.modal)} style={stylesProp.modal} {...getModalHideOnClickProps()}>
        <div className={classNames(styles['modal-dialog-container'], classes.modalDialogContainer)}>
          <div className={classNames(styles['modal-dialog'], classes.modalDialog)}>
            <div className={styles['circle-close-section']}>
              {showCircleClose && (
                <IconButton onClick={handleClickCircleClose}>
                  <CircleClose className={styles['circle-close-icon']} htmlColor="#ffffff" />
                </IconButton>
              )}
            </div>
            <div className={classNames(styles['modal-section'], classes.modalSection)}>
              <div className={styles['modal-content-container']}>
                <div
                  className={classNames(styles['modal-content'], classes.modalContent)}
                  {...getModalContentHideOnClickProps()}
                >
                  <div
                    className={classNames('card-box', styles['modal-content-2'], classes.modalContent2)}
                    style={stylesProp.modalContent2}
                  >
                    {children}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>,
    document.body
  );
}

export const DuetIconContainer = ({ children }) => {
  return <div className={styles['modal-icon-container']}>{children}</div>;
};

export const DuetIcon = ({ containerStyles, type = 'question' }) => {
  return (
    <div className={styles['modal-icon-container']} style={containerStyles}>
      {type === 'question' && <Question className={styles.icon} />}
      {type === 'info' && <Info className={styles.icon} />}
      {type === 'check' && <Checkmark className={styles.icon} />}
      {type === 'mail' && <Mail className={styles.icon} />}
    </div>
  );
};

export const DuetFullButtons = ({ children, customStyles, mobileSingleRow = false }) => {
  return (
    <div
      className={classNames({
        [styles['modal-buttons-section']]: true,
        [styles['buttons-full']]: true,
        [styles['mobile-single-row']]: mobileSingleRow,
      })}
      style={customStyles}
    >
      {children}
    </div>
  );
};

export const DuetLeftRightButtons = ({ children, customStyles, mobileSingleRow = false }) => {
  return (
    <div
      className={classNames({
        [styles['modal-buttons-section']]: true,
        [styles['mobile-single-row']]: mobileSingleRow,
      })}
      style={customStyles}
    >
      {children}
    </div>
  );
};

const SingleColumnButtons = ({ renderFirstButton, renderSecondButton, renderThridButton, mobileOrientationConfig }) => {
  const buttons = [
    { key: 'third', button: renderThridButton },
    { key: 'first', button: renderFirstButton },
    { key: 'second', button: renderSecondButton },
  ];

  const sortedButtons = mobileOrientationConfig
    ? buttons.sort((a, b) => {
        if (mobileOrientationConfig[a.key] <= mobileOrientationConfig[b.key]) {
          return -1;
        }

        return 1;
      })
    : buttons;

  return sortedButtons.map((buttonObj) => <React.Fragment key={buttonObj.key}>{buttonObj.button}</React.Fragment>);
};

// orientation:
// LRR: left, right, right
// AR: all right
export const DuetTripleButtons = ({
  customStyles,
  renderFirstButton,
  renderSecondButton,
  renderThridButton,
  orientation = 'LRR',
  mobileOrientation = 'PYRAMID',
  mobileOrientationConfig,
}) => {
  const { width } = useWindowSize();
  const isMobile = width <= 640;

  return (
    <div
      className={classNames(styles['modal-triple-buttons-section'], {
        [styles['single-column-mobile']]: mobileOrientation === 'SINGLE_COLUMN',
      })}
      style={customStyles}
    >
      {!isMobile && orientation === 'LRR' && (
        <>
          {renderFirstButton}
          <div className={classNames(styles.row)}>
            {renderSecondButton}
            {renderThridButton}
          </div>
        </>
      )}
      {!isMobile && orientation === 'AR' && (
        <div className={classNames(styles.row, styles.ar)}>
          {renderFirstButton}
          {renderSecondButton}
          {renderThridButton}
        </div>
      )}
      {isMobile && mobileOrientation === 'PYRAMID' && (
        <>
          {renderThridButton}
          <div className={classNames(styles.row)}>
            {renderFirstButton}
            {renderSecondButton}
          </div>
        </>
      )}
      {isMobile && mobileOrientation === 'SINGLE_COLUMN' && (
        <SingleColumnButtons
          renderFirstButton={renderFirstButton}
          renderSecondButton={renderSecondButton}
          renderThridButton={renderThridButton}
          mobileOrientationConfig={mobileOrientationConfig}
        />
      )}
    </div>
  );
};
