import { isString } from 'lodash';

export const stringHasSubstring = (str, substring, isCaseSensitive = false) => {
  if (isCaseSensitive) {
    return String(str).includes(String(substring));
  }

  return String(str)
    .toLowerCase()
    .includes(String(substring).toLowerCase());
};

export const nullEmptyString = (str) => {
  if (isString(str) && str.trim() === '') {
    return null;
  }

  return str;
};

export const titleCase = (str) => {
  if (!str) {
    return '';
  }

  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const overflowEllipsis = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str;
  }

  return `${str.substring(0, maxLength)}...`;
};

export const trimInsignificantLeadingZeroes = (numStr) => {
  if (/^0+$/.test(numStr)) {
    return '0';
  }

  return numStr.replace(/^0+/, '');
};

export const stringSortComparator = (
  a = '',
  b = '',
  { trim = false, caseSensitive = false } = { trim: false, caseSensitive: false }
) => {
  let A = trim ? a.trim() : a;
  let B = trim ? b.trim() : b;

  A = caseSensitive ? A : A.toLowerCase();
  B = caseSensitive ? B : B.toLowerCase();

  return A.localeCompare(B);
};

export const capitalizeEachWord = (str) => {
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
};

export const removeDoubleZeroDecimals = (str) => {
  const tokens = str.split('.');

  if (tokens[1] === '00') {
    return tokens[0];
  }

  return str;
};

export const isStringNumber = (str) => {
  return /^\d*$/.test(str);
};
