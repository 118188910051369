/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

import React from 'react';

import styles from './vehicle-info.module.scss';

const VehicleInfo = ({ vehicle, uniqueKey, onClick }) => {
  return (
    <div onClick={onClick} className={styles.vehicleInfoContainer} id={uniqueKey}>
      <div className={styles.row} style={{ justifyContent: 'space-between' }}>
        <div>
          <div className={styles.row}>
            <div className={styles.bold}>Name:&nbsp;</div>
            <div>{vehicle.shuttle_name} </div>
          </div>
          <div className={styles.row}>
            <div className={styles.bold}>Make:&nbsp;</div>
            <div>{vehicle.shuttle_make} </div>
          </div>
          <div className={styles.row} style={{ marginBottom: 0 }}>
            <div className={styles.bold}>License:&nbsp;</div>
            <div>{vehicle.shuttle_license_plate} </div>
          </div>
        </div>
        {vehicle.shuttle_photo && <img className={styles.img} src={vehicle.shuttle_photo} alt={vehicle.shuttle_name} />}
      </div>
      <div className={styles.row}>
        <div className={styles.bold}>Wheelchair:&nbsp;</div>
        <div>{vehicle.shuttle_wheelchair} </div>
      </div>
      <div className={styles.row}>
        <div className={styles.bold}>Stretcher:&nbsp;</div>
        <div>{vehicle.shuttle_stretcher} </div>
      </div>
      <div className={styles.row}>
        <div className={styles.bold}>Credential:&nbsp;</div>
        <div>
          {vehicle?.shuttle_broker_certification?.map((cert) => {
            if (cert.is_active) {
              return `${cert.insurance_name} `;
            }
            return null;
          })}{' '}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.bold}>Note:&nbsp;</div>
        <div className="text-left">{vehicle.shuttle_note}</div>
      </div>
    </div>
  );
};

export default VehicleInfo;
