/* eslint-disable import/prefer-default-export */

export const sortBookings = (stops) => {
  return stops
    .sort((a, b) => a.address.localeCompare(b.address)) // Group stops by address
    .sort((a, b) => {
      const getTime = (booking) => booking.scheduled_pickup_time || booking.scheduled_dropoff_time;

      const timeA = new Date(getTime(a.bookings[0])).getTime();
      const timeB = new Date(getTime(b.bookings[0])).getTime();
      const stopTypeA = a.bookings[0].stop_type === 'dropoff' ? 0 : 1;
      const stopTypeB = b.bookings[0].stop_type === 'dropoff' ? 0 : 1;
      const firstNameA = a.bookings[0].passenger_firstname.toLowerCase();
      const firstNameB = b.bookings[0].passenger_firstname.toLowerCase();
      const lastNameA = a.bookings[0].passenger_lastname.toLowerCase();
      const lastNameB = b.bookings[0].passenger_lastname.toLowerCase();

      // Sort by time first
      if (timeA !== timeB) {
        return timeA - timeB;
      }
      // Sort by stop_type ("dropoff" first)
      if (stopTypeA !== stopTypeB) {
        return stopTypeA - stopTypeB;
      }
      // Sort by passenger_firstname
      if (firstNameA !== firstNameB) {
        return firstNameA.localeCompare(firstNameB);
      }
      // Sort by passenger_lastname
      return lastNameA.localeCompare(lastNameB);
    });
};
