/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';

import Form from 'react-bootstrap/Form';

import CloseIcon from '../../../assets/images/close2.svg';
import { withGoogleMap, GoogleMap, Polygon, OverlayView } from 'react-google-maps';

import styles from './heat-map.module.scss';

const url = `https://maps.googleapis.com/maps/api/js?key=${
  process.env.REACT_APP_GOOGLEAPIKEY
}&v=3.exp&libraries=geometry,drawing,places`;

const customStyles = [
  {
    featureType: 'all',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.school',
    elementType: 'labels.text',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'poi.business',
    stylers: [{ visibility: 'off' }],
  },
  {
    featureType: 'transit',
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
];

const GoogleMapComponent = withGoogleMap(({ heatMap, showColor }) => {
  const mapRef = useRef(null);
  const label = ['F', 'E', 'D', 'C', 'B', 'A'];

  useEffect(() => {
    setUpBounds();
  }, [mapRef.current]);

  const setUpBounds = () => {
    const bounds = new window.google.maps.LatLngBounds();
    heatMap.map((row) => {
      return row.map((coor) => {
        bounds.extend(new window.google.maps.LatLng(coor.latitude, coor.longitude));
        return coor;
      });
    });

    setTimeout(() => {
      if (mapRef.current) {
        mapRef.current.fitBounds(bounds);
      }
    }, 500);
  };

  const getPixelPositionOffset = (width, height) => ({
    x: -(width / 2),
    y: -(height / 2),
  });

  return (
    <GoogleMap
      ref={mapRef}
      mapTypeId="roadmap"
      options={{
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        scaleControl: false,
        streetViewControl: false,
        controlSize: 27,
        styles: customStyles,
        trackViewChanges: false,
      }}
    >
      {// we will create a 6x6 polygon on the map
      [1, 2, 3, 4, 5, 6].map((index) => {
        return [1, 2, 3, 4, 5, 6].map((index2) => {
          const topRight = {
            lat: heatMap[index - 1][index2 - 1].latitude,
            lng: heatMap[index - 1][index2 - 1].longitude,
          };
          const bottomRight = {
            lat: heatMap[index - 1][index2].latitude,
            lng: heatMap[index - 1][index2 - 1].longitude,
          };
          const bottomLeft = {
            lat: heatMap[index][index2].latitude,
            lng: heatMap[index][index2].longitude,
          };
          const topLeft = {
            lat: heatMap[index][index2 - 1].latitude,
            lng: heatMap[index][index2 - 1].longitude,
          };

          const bounds = new window.google.maps.LatLngBounds();

          const polygonCoords = [
            new window.google.maps.LatLng(topRight.lat, topRight.lng),
            new window.google.maps.LatLng(bottomRight.lat, bottomRight.lng),
            new window.google.maps.LatLng(bottomLeft.lat, bottomLeft.lng),
            new window.google.maps.LatLng(topLeft.lat, topLeft.lng),
          ];

          for (let i = 0; i < polygonCoords.length; i++) {
            bounds.extend(polygonCoords[i]);
          }

          return (
            <React.Fragment key={`heat-map${index}-${index2}`}>
              <Polygon
                path={[
                  { lat: topRight.lat, lng: topRight.lng },
                  { lat: bottomRight.lat, lng: bottomRight.lng },

                  { lat: bottomLeft.lat, lng: bottomLeft.lng },
                  { lat: topLeft.lat, lng: topLeft.lng },
                ]}
                options={{
                  fillColor: `#${heatMap[index2 - 1][index].color}`,
                  fillOpacity: showColor ? 1 : 0,
                  strokeColor: '#000',
                  strokeOpacity: 1,
                  strokeWeight: 1,
                }}
              />

              <OverlayView
                position={{
                  lat: bounds.getCenter().lat(),
                  lng: bounds.getCenter().lng(),
                }}
                mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET}
                getPixelPositionOffset={getPixelPositionOffset}
              >
                <div className={styles.polygon__label}>
                  <strong>{`${label[index - 1]}${index2}`}</strong>
                </div>
              </OverlayView>
            </React.Fragment>
          );
        });
      })}
    </GoogleMap>
  );
});

const HeatMapInfo = ({ heatMap, hideHeatMapMap }) => {
  const [showColor, setShowColor] = useState(false);

  const handleClose = (e) => {
    if (e.target.id === 'heatmap') {
      hideHeatMapMap();
    }
  };

  return (
    <div className={styles.modal} id="heatmap" onClick={handleClose}>
      <div className={styles.modal__content}>
        <div className={styles.close__container} onClick={hideHeatMapMap}>
          <img className="info-icon" src={CloseIcon} alt="" />
        </div>
        <div className={styles.heading}>How does the Heatmap work?</div>
        <div className={styles.message}>
          Heatmap color codes the addresses in the bookings according to the color pattern below. The color coding
          allows you to use color matching in dispatching, i.e., the shorter the distance is between the two addresses,
          the close their colors are.{' '}
        </div>
        <div className={styles.toggle} onClick={() => setShowColor(!showColor)}>
          <Form.Check
            type="radio"
            id={styles['#colorMapToggle']}
            label={`${' '}${' '}${' '}Add color pattern`}
            checked={showColor}
            onClick={() => setShowColor(!showColor)}
            onChange={() => setShowColor(!showColor)}
          />
        </div>
        <div className={styles.map__container}>
          {heatMap.length > 0 ? (
            <GoogleMapComponent
              showColor={showColor}
              heatMap={heatMap}
              googleMapURL={url}
              loadingElement={<div className={styles.heat__map_container} />}
              containerElement={<div className={styles.heat__map_container} />}
              mapElement={<div className={styles.google_map} />}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default HeatMapInfo;
