import React, { useState } from 'react';

import RemoveSharedTrips from './remove-trip';

import { getOptimizedRouteTravelData } from '../../../services/dispatch';
import UpdatePickupTime from './updatePickupTime';
import { combineBookingsByAddress } from '../../../lib/common';

const EditSharedTripFlow = ({
  bookings,
  selectedDate,
  close,
  unDispatchedBooking,
  setSpinner,
  unsetSpinner,
  backToDispatchBoard,
  programId,
  shuttleAnchorLocation,
  shuttleId,
  tripId,
  updateTimeAfterShareTripsUpdate,
}) => {
  const [viewModal, setViewModal] = useState('remove');
  const [updatedBookings, setUpdatedBookings] = useState([]);
  const [trips, setTrips] = useState();
  const [hasChanged, setHasChanged] = useState(false);

  const handleGetOptimizedTravelRoute = async (stopOrder) => {
    try {
      setSpinner();
      setUpdatedBookings(stopOrder);
      const params = {
        stop_order: hasChanged ? stopOrder : combineBookingsByAddress(stopOrder),
        shuttle_id: shuttleId,
        shuttle_anchor_location: shuttleAnchorLocation,
      };
      console.log('Request params:', params); // Debugging request parameters
      const resp = await getOptimizedRouteTravelData(params);
      console.log('Response:', resp); // Debugging API response
      if (resp.status === 'success') {
        setTrips(resp.data);
        setViewModal('update');
      } else {
        console.log(resp);
      }
    } catch (error) {
      console.error('Error caught:', error); // Debugging error
    }

    unsetSpinner();
  };

  return (
    <>
      {viewModal === 'remove' && (
        <RemoveSharedTrips
          bookings={bookings}
          selectedDate={selectedDate}
          close={close}
          unDispatchedBooking={unDispatchedBooking}
          setSpinner={setSpinner}
          unsetSpinner={unsetSpinner}
          backToDispatchBoard={backToDispatchBoard}
          handleNext={handleGetOptimizedTravelRoute}
          shuttleAnchorLocation={shuttleAnchorLocation}
          setHasChanged={setHasChanged}
        />
      )}

      {viewModal === 'update' && (
        <UpdatePickupTime
          trips={trips}
          close={backToDispatchBoard}
          setSpinner={setSpinner}
          unsetSpinner={unsetSpinner}
          bookings={updatedBookings}
          dispatchDate={selectedDate}
          programId={programId}
          shuttleAnchorLocation={shuttleAnchorLocation}
          tripId={tripId}
          updateTimeAfterShareTripsUpdate={updateTimeAfterShareTripsUpdate}
        />
      )}
    </>
  );
};

export default EditSharedTripFlow;
