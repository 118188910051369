import React from 'react';
import { Modal, ModalBody } from 'react-bootstrap';

import styles from './modal.module.scss';
import './override.css';

const DuetModal = ({ close, show, dialogClassName = styles.modal_width, children }) => {
  return (
    <Modal show={show} centered onHide={close} dialogClassName={dialogClassName}>
      <ModalBody>
        <div className={`${styles.container} card-box`}>{children}</div>
      </ModalBody>
    </Modal>
  );
};

export const DuetIconContainer = ({ children }) => {
  return <div className={`${styles.icon__container} text-center`}>{children}</div>;
};

export const DuetFullButtons = ({ children, customStyles, customClass }) => {
  return (
    <div className={`${styles.button__container} ${customClass || ''}`} style={customStyles}>
      <div className={`${styles.full__button} text-right`}>{children}</div>
    </div>
  );
};

export const DuetLeftRightButtons = ({ children, customStyles }) => {
  return (
    <div className={styles.button__container} style={customStyles}>
      {children}
    </div>
  );
};

export default DuetModal;
