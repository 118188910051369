/* eslint-disable */

import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import styles from './styles.module.scss';
import TripsOnMap from './trips-on-googlemap';
import displayNotification from '../../../utils/notification';
import { sortBookings } from './utils';

const { host, protocol } = window.location;
const dropOffUrl = `${protocol}//${host}/resources/images/dropoff_pin_same-size.png`;
const pickupOffUrl = `${protocol}//${host}/resources/images/pickup_pin_same-size.png`;
const RemoveIcon = `${protocol}//${host}/resources/images/Remove.svg`;
const SameAddress = 'Same address as above';
const TripReorder = ({ close, bookings: propsBookings, handleNext, hasChanged: propsHasChanged }) => {
  // used in reording UI
  const [trips, setTrips] = useState([]);
  // used in google maps
  const [bookings, setBookings] = useState([]);
  const [hasChanged, setHasChange] = useState(propsHasChanged);

  // Tracks the index of the dragged trip
  const [draggingIndex, setDraggingIndex] = useState(null);

  const handleDragStart = (e, index) => {
    setDraggingIndex(index);
    // Create a custom drag UI
    // const dragImage = document.createElement('div');
    // dragImage.style.width = '500px';
    // dragImage.style.height = '50px';
    // dragImage.background = '#f7f8fa !important';
    // dragImage.border = '1px solid red !important';
    // dragImage.style.backgroundColor = '#f7f8fa !important';
    // dragImage.style.color = '#4a525f !important';
    // dragImage.style.display = 'block';
    // dragImage.style.justifyContent = 'center';
    // dragImage.style.alignItems = 'center';
    // dragImage.style.opacity = '0.9';

    // // dragImage.style.position = 'absolute';
    // // dragImage.style.top = '0px';
    // // dragImage.style.left = '0px';
    // dragImage.textContent = 'Dragging!';
    // document.body.appendChild(dragImage);

    // // Force reflow to ensure the element is rendered
    // dragImage.style.transform = 'translateY(0)';

    // // Set the custom drag image
    // e.dataTransfer.setDragImage(dragImage, dragImage.offsetWidth / 2, dragImage.offsetHeight / 2);

    // // Clean up the drag image after drag ends
    // e.target.addEventListener(
    //   'dragend',
    //   () => {
    //     document.body.removeChild(dragImage);
    //   },
    //   { once: true }
    // );
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    let newTrips = [...trips];
    const draggedTrip = newTrips[draggingIndex];

    // Remove the dragged trip from its old position
    newTrips.splice(draggingIndex, 1);

    // Insert it at the new index
    newTrips.splice(index, 0, draggedTrip);

    newTrips = [...validateTripsOrder(newTrips)];
    setTrips([...newTrips]);
    // setTrips(sortBookings(newTrips));
    setDraggingIndex(index);
    setHasChange(true);
  };

  const handleDrop = (e) => {
    setTrips([...trips]);
    // setTrips(sortBookings([...trips]));
    setDraggingIndex(null);
    setHasChange(true);
  };

  // On drag stop, ensure that each drag item is properly ordered with its pair.
  // When an item is pickup type, its paired drop-off point should be positioned below it, not above, and vice versa.
  const validateTripsOrder = (validateTrips) => {
    let newTrips = [...validateTrips];
    if (validateTrips[draggingIndex]) {
      const bookingId = validateTrips[draggingIndex].bookings[0].id;
      const stopType = validateTrips[draggingIndex].bookings[0].stop_type;

      // this is the index of dragged item after drop is done
      const indexAfter = validateTrips.findIndex(
        ({ bookings }) => bookings[0].stop_type === stopType && bookings[0].id === bookingId
      );
      // this is the index of the paired  dragged
      const indexOfPair = validateTrips.findIndex(
        ({ bookings }) => bookings[0].stop_type !== stopType && bookings[0].id === bookingId
      );

      if (validateTrips[indexAfter] && validateTrips[indexOfPair]) {
        if (stopType === 'pickup' && indexAfter > indexOfPair) {
          newTrips = moveArrayElement([...validateTrips], indexOfPair, indexAfter + 1);
          setTrips([...newTrips]);
          // setTrips(sortBookings([...newTrips]));
        } else if (stopType === 'dropoff' && indexAfter < indexOfPair) {
          newTrips = moveArrayElement([...validateTrips], indexOfPair, indexAfter);
        }
      }
    }
    return newTrips;
  };

  const moveArrayElement = (arr, fromIndex, toIndex) => {
    // Adjust for -1 (move to end) and out-of-bounds index
    if (toIndex < 0) {
      toIndex = arr.length - 1;
    } else if (toIndex >= arr.length) {
      toIndex = arr.length - 1;
    }

    // Remove the element from the original index
    const element = arr.splice(fromIndex, 1)[0];

    // Insert it at the new index
    arr.splice(toIndex, 0, element);

    return arr;
  };

  const handleRemove = (booking) => {
    // remove pickup and dropoff booking
    let tempBookings = [...trips];
    tempBookings = [
      ...tempBookings.map((data) => ({
        ...data,
        bookings: [...data.bookings.filter(({ id }) => id !== booking.id)],
      })),
    ];

    // remove all trips where bookings legnth equal to O
    tempBookings = [...tempBookings.filter((data) => data.bookings.length > 0)];

    if (tempBookings.length > 2) {
      setTrips([...tempBookings]);
      // setTrips(sortBookings([...tempBookings]));
      setBookings([...tempBookings]);
      setHasChange(true);
    } else displayNotification('At least two or more trips are needed for this feature.', 'Delete failed');
  };

  const handleLockThisRoute = () => {
    if (hasChanged) return handleNext(trips, trips, true);
    const combinedDataByAddress = [];
    const addressMap = new Map();

    trips.forEach(({ address, bookings, id, account_id, place_id, geoLocation, mixed_type, isMoved }) => {
      if (!addressMap.has(address)) {
        // Create a new entry for the first occurrence
        const entry = { address, bookings: [], id, account_id, place_id, geoLocation, mixed_type, isMoved };
        combinedDataByAddress.push(entry);
        addressMap.set(address, entry);
      }
      // Add bookings to the existing entry
      const currentEntry = addressMap.get(address);
      currentEntry.bookings = currentEntry.bookings.concat(bookings);
    });

    handleNext(combinedDataByAddress, trips, hasChanged);
  };

  const checkIfSameAddress = (address, index) => {
    // Check if the current address is the first occurrence in the array
    if (index > 0 && trips[index].address === trips[index - 1].address) {
      return SameAddress;
    }
    return address;
  };

  useEffect(() => {
    setTrips(sortBookings(propsBookings));
    setBookings(propsBookings);
  }, [propsBookings]);

  useEffect(() => {
    setHasChange(propsHasChanged);
  }, [propsHasChanged]);

  return (
    <div className={styles.modalContainer}>
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          <div className={styles.tripReorderContainer}>
            <div className={styles.mapContainer}>
              <TripsOnMap bookings={bookings} />
            </div>
            <div className={styles.tripsContainer}>
              <div className={styles.header}>
                <div>Shared Trip Setup</div>
                <div className={styles.closeIcon}>
                  <IconButton onClick={close}>
                    <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    </svg>
                  </IconButton>
                </div>
              </div>

              <div className={`mt-3 ${styles.tripReorder}`}>
                <div className={styles.subHeader}>Step 1: Optimize route</div>
                <p className="mt-2 ">
                  We&apos;ve optimized the route and found the shortest distance. You can either take the result or
                  adjust it further yourself by dragging the pickup or drop-off blocks up and down.
                </p>
                <p className="mt-3">
                  Once you&apos;re satisfied with the route, just proceed to the next step. Please ignore the time at
                  this step. You will adjust the time at the next step.
                </p>

                <ul className={`mt-4 ${styles.tripList}`}>
                  {trips.map((trip, index) => {
                    const booking = trip.bookings[0];
                    const isPickup = booking.stop_type === 'pickup';
                    const time = isPickup ? booking.scheduled_pickup_time : booking.scheduled_dropoff_time;
                    const tripTime = new Date(time).toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                    });

                    const address = checkIfSameAddress(trip.address, index);

                    return (
                      <li
                        key={`${trip.id}${index}`}
                        draggable
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={(e) => handleDragOver(e, index)}
                        onDrop={handleDrop}
                        className={styles.tripItem}
                      >
                        <div className={styles.tripIcon}>
                          <img src={isPickup ? pickupOffUrl : dropOffUrl} alt="tripIcon" />
                        </div>
                        <div className={styles.tripDetails}>
                          <div className={`${styles.tripName} ${address === SameAddress && styles.sameAddress}`}>
                            {address}
                          </div>
                          <div className={styles.tripAddress}>
                            {tripTime} {booking.passenger_firstname} {booking.passenger_lastname}{' '}
                            {booking.mobility_assistance.length
                              ? booking.mobility_assistance.map((mob) => `, ${mob}`)
                              : ''}
                          </div>
                        </div>
                        <div className={styles.removeIcon} onClick={() => handleRemove(booking)}>
                          <img src={RemoveIcon} alt="remove" />
                        </div>
                        <div className={styles.grabIcon}>
                          <img src="/resources/images/vertical-grab.svg" alt="grab" />
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <div className="mt-4">
                  <button className="btn btn-secondary" onClick={close}>
                    Cancel
                  </button>
                  <button className="btn btn-primary" style={{ float: 'right' }} onClick={handleLockThisRoute}>
                    Use this route
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripReorder;
