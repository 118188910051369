import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Checkmark(props) {
  return (
    <SvgIcon width="90" height="90" fill="none" viewBox="0 0 90 90" {...props}>
      <circle cx="45" cy="45" r="43" stroke="#118BF0" strokeWidth="4" />
      <path
        d="M63.0467 29.6904C63.9511 28.7699 65.4174 28.7699 66.3217 29.6904C67.2261 30.6109 67.2261 32.1034 66.3217 33.0239L38.5322 61.3096C37.6279 62.2301 36.1616 62.2301 35.2572 61.3096L23.6783 49.5239C22.7739 48.6034 22.7739 47.1109 23.6783 46.1904C24.5827 45.2699 26.0489 45.2699 26.9533 46.1904L36.8947 56.3094L63.0467 29.6904Z"
        fill="#118BF0"
      />
    </SvgIcon>
  );
}
