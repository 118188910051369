import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Mail(props) {
  return (
    <SvgIcon width="82" height="66" fill="none" viewBox="0 0 82 66" {...props}>
      <path
        d="M13.1428 0.857422H68.8571C72.267 0.857422 75.5373 2.21201 77.9485 4.62319C80.3596 7.03437 81.7142 10.3046 81.7142 13.7146V52.286C81.7142 55.6959 80.3596 58.9662 77.9485 61.3774C75.5373 63.7885 72.267 65.1431 68.8571 65.1431H13.1428C9.73286 65.1431 6.4626 63.7885 4.05142 61.3774C1.64023 58.9662 0.285645 55.6959 0.285645 52.286V13.7146C0.285645 10.3046 1.64023 7.03437 4.05142 4.62319C6.4626 2.21201 9.73286 0.857422 13.1428 0.857422ZM13.1428 5.14314C11.1318 5.13976 9.18429 5.84712 7.64422 7.14028L40.9999 28.8003L74.3557 7.14028C72.8152 5.84776 70.8679 5.1405 68.8571 5.14314H13.1428ZM40.9999 33.9088L5.14565 10.6289C4.76545 11.6132 4.57074 12.6594 4.57136 13.7146V52.286C4.57136 54.5593 5.47442 56.7395 7.08187 58.3469C8.68933 59.9544 10.8695 60.8574 13.1428 60.8574H68.8571C71.1304 60.8574 73.3105 59.9544 74.918 58.3469C76.5254 56.7395 77.4285 54.5593 77.4285 52.286V13.7146C77.4285 12.626 77.2271 11.5846 76.8542 10.6289L40.9999 33.9046V33.9088Z"
        fill="#118BF0"
      />
    </SvgIcon>
  );
}
