/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import React from 'react';

import { Form } from 'react-bootstrap';
import styles from './heat-map.module.scss';

const HeatMapButtons = ({ handleClick, showHeatMap, toggleClass = '', handleHeatMapInfo }) => {
  return (
    <>
      <div className={`heatmap-icon ${toggleClass}`}>
        <Form.Check // prettier-ignore
          type="radio"
          id="heatMapToggle"
          label=""
          checked={showHeatMap}
          onClick={handleClick}
          onChange={handleClick}
        />
      </div>
      <span className={styles.RadioLabel} onClick={handleHeatMapInfo}>
        HeatMap
      </span>
    </>
  );
};

export default HeatMapButtons;
